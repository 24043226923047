import { useEventListener } from '../../hooks/useEventListener';
import { CustomElement } from '@horizon/common/types';
import { HznInput, HznInputHTML, HznInputInstance } from '@horizon/input';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

export type InputErrorMessages = Partial<Record<keyof ValidityState, string>>;

export interface InputHandle {
    validate: () => boolean;
}

interface InputProps {
    label: string;
    className?: string;
    onValueChanged: (value: string | undefined) => void;
    errorMessages?: InputErrorMessages;
}

const Input = forwardRef<InputHandle, InputProps & CustomElement<HznInputInstance, HznInputHTML>>(
    ({ label, className, onValueChanged, errorMessages, ...attrs }, fwdRef) => {
        const inputRef = useRef<HznInput>();

        useEffect(() => {
            if (inputRef.current && errorMessages) {
                inputRef.current.errorMessages = errorMessages;
            }
        }, [inputRef, errorMessages]);

        const onChange = useCallback(
            (target: HznInput) => onValueChanged(target.value === '' ? undefined : target.value),
            [onValueChanged]
        );

        useEventListener(inputRef, 'input', onChange);
        useEventListener(inputRef, 'change', onChange);

        // hook for external validation invocation
        useImperativeHandle(fwdRef, () => ({
            validate: () => {
                if (!inputRef.current) return false;
                inputRef.current.checkValidity();
                return inputRef.current.validity.valid;
            },
        }));

        return (
            <hzn-input ref={inputRef} class={className} name={label} display-name={label} {...attrs}>
                {label}
            </hzn-input>
        );
    }
);

export default Input;
