import Claims from '@src/models/Claims';
import { Outlet } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import { FlexColumn } from './Flex';

export const Layout = () => {
    const profile = JSON.parse(sessionStorage.getItem('com.unboundid.kmxpos.profile')) as Claims;

    return (
        <FlexColumn className="gap-5 h-100 vh-100">
            <NavMenu userdata={profile} />
            <hzn-stack align-x="center">
                <div className="p-4 w-90">
                    <Outlet />
                </div>
            </hzn-stack>
        </FlexColumn>
    );
};
