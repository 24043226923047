import { AppInsightsErrorBoundary, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { reactPlugin } from './common/appinsights';
import { GenericNotFound } from './components/GenericNotFound';
import { Layout } from './components/Layout';
import { ToastStack } from './components/toast/ToastStack';
import { BasketSearchPage } from './pages/BasketSearch/BasketSearchPage';
import { store } from './store/store';
import { StoreOutlet } from './components/StoreOutlet';
import Claims from './models/Claims';
import { BasketCheckout } from './pages/BasketCheckout/BasketCheckout';

export const AppRoutes = ({ claims }: { claims: Claims }) => (
    <AppInsightsErrorBoundary onError={() => <></>} appInsights={reactPlugin}>
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="checkout" element={<Layout />}>
                            <Route index element={<Navigate to={claims['kmx.locationnumber'][0]} />} />
                            <Route path=":storeLocationId" element={<StoreOutlet />}>
                                <Route index element={<BasketSearchPage />} />
                                <Route path=":basketId" element={<BasketCheckout />} />
                            </Route>
                            <Route path="*" element={<GenericNotFound />} />
                        </Route>
                        <Route path="*" element={<Navigate to="/checkout" replace />} />
                    </Routes>
                </BrowserRouter>
                <ToastStack />
            </Provider>
        </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
);
