import { CardReaderConnectionStatus, CardReaderInputStatus, CashDrawerStatus } from '@src/api/hardware/EchoApi';
import Select from '@src/components/horizon/Select';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import {
    selectMockCardReader,
    selectMockCashDrawer,
    selectMockWorkstation,
    selectUseMockHardware,
    setMockCardReaderStatus,
    setMockCardReaderSwipeStatus,
    setMockCashDrawerStatus,
    setMockWorkstationName,
    setUseMockHardware,
} from '@src/store/MockHardwareSlice';
import { HznBool } from '@src/utils/Horizon';
import { boolean } from 'yargs';
import { FlexColumn } from '../Flex';
import Input from '../horizon/Input';

export const MockHardwareControl = () => {
    const dispatch = useAppDispatch();
    const useMockHardware = useAppSelector(selectUseMockHardware);
    const cashDrawer = useAppSelector(selectMockCashDrawer);
    const cardReader = useAppSelector(selectMockCardReader);
    const workstation = useAppSelector(selectMockWorkstation);

    return (
        <FlexColumn className="gap-4">
            <Select
                label="Mock Hardware"
                value={useMockHardware.toString()}
                onSelectionChanged={value => dispatch(setUseMockHardware(value === 'true'))}
                compact
            >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
            </Select>
            <hzn-divider />
            <Select
                label="Cash Drawer Status"
                value={cashDrawer.status}
                onSelectionChanged={value => dispatch(setMockCashDrawerStatus(value as CashDrawerStatus))}
                disabled={HznBool(!useMockHardware)}
                compact
            >
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
                <option value="Offline">Offline</option>
            </Select>
            <hzn-divider />
            <Select
                label="Card Reader Status"
                value={cardReader.status}
                onSelectionChanged={value => dispatch(setMockCardReaderStatus(value as CardReaderConnectionStatus))}
                disabled={HznBool(!useMockHardware)}
                compact
            >
                <option value="Connected">Connected</option>
                <option value="Disconnected">Disconnected</option>
                <option value="Error">Error</option>
            </Select>
            <Select
                label="Card Swipe Result"
                value={cardReader.cardReadResult.status}
                onSelectionChanged={value => dispatch(setMockCardReaderSwipeStatus(value as CardReaderInputStatus))}
                disabled={HznBool(!useMockHardware)}
                compact
            >
                <option value="Success">Success</option>
                <option value="Manual">Manual</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Retrying">Retrying</option>
                <option value="Error">Error</option>
            </Select>
            <hzn-divider />
            <Input
                label="Workstation Name"
                value={workstation.name}
                onValueChanged={value => dispatch(setMockWorkstationName(value))}
                disabled={HznBool(!useMockHardware)}
                compact                
            />
        </FlexColumn>
    );
};
