import React, { useEffect } from 'react';
import { BasketSearchTerm } from './BasketSearchTerm';
import {
    selectBasketSearchQuery,
    updatePageNumber,
    selectBasketSearchRequest,
    updatePageSize,
    updateSellingLocation,
} from './BasketSearchSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { NoResultsAlert } from './NoResultsAlert';
import { BasketSearchResults } from './BasketSearchResults';
import { Pager } from '../../components/pager/Pager';
import { BasketPaymentStateFilter } from './BasketPaymentStateFilter';
// import { logger } from '../../common/logger';
import { useParams } from 'react-router-dom';
import { useGetBasketSummariesQuery } from '@src/api/CheckoutApi';

export const BasketSearchPage = ({ storeLocationIdFromProps = undefined}: { storeLocationIdFromProps?: string }) => {
    usePageTitle('Cart Search');
    const { storeLocationId } = useParams();
    const dispatch = useAppDispatch();
    const searchQuery = useAppSelector(selectBasketSearchQuery);
    const searchRequest = useAppSelector(selectBasketSearchRequest);
    const searchResult = useGetBasketSummariesQuery(searchRequest, {
        refetchOnMountOrArgChange: true,
        skip: !searchRequest?.sellingLocationId
    });

    useEffect(() => {
        dispatch(updateSellingLocation(storeLocationIdFromProps ?? storeLocationId));
    }, [storeLocationIdFromProps, storeLocationId]);

    return (
        <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-row gap-4 align-items-center">
                <hzn-heading as="h2" size="small" class="w-25">
                    Checkout
                </hzn-heading>
                <div className="ms-auto w-50">
                    <BasketSearchTerm />
                </div>
                <div className="w-25">
                    <BasketPaymentStateFilter />
                </div>
            </div>
            <div className="d-flex flex-column gap-4">
                {searchResult.isError && (
                    <hzn-alert
                        tone="critical"
                        heading="Cart search error"
                        sub-heading={JSON.stringify(searchResult.error)}
                    />
                )}
                {!searchResult.isFetching && searchResult.isSuccess && searchResult.data && searchResult.data.totalCount === 0 && (
                    <div className="pt-5">
                        <NoResultsAlert clearable={!!searchQuery.term || !!searchQuery.paymentState} />
                    </div>
                )}
                {(searchResult.isFetching || (searchResult.isSuccess && searchResult.data && searchResult.data.totalCount !== 0)) && (
                    <BasketSearchResults query={searchQuery.term} loading={searchResult.isFetching} results={searchResult?.data}/>
                )}
                {searchResult.isSuccess && searchResult.data && searchResult.data.totalCount !== 0 && (
                    <Pager
                        pageNumber={searchQuery.page.pageNumber}
                        totalCount={searchResult.data.totalCount}
                        onPageChange={page => dispatch(updatePageNumber(page))}
                        onItemsPerPageChange={itemsPerPage => dispatch(updatePageSize(itemsPerPage))}
                    />
                )}
            </div>
        </div>
    );
};
