import { HttpResponse, delay, http } from 'msw';
import { generateMockBasketSummaries, generateMockBasketsDb } from './mockCheckoutDataV2';
import orderBy from 'lodash/orderBy';
import {
    BasketDto,
    BasketPaymentActionRequestDto,
    BasketSummaryDto,
    CertifiedFundsPaymentDto,
    CheckPaymentDto,
    CreateBasketPaymentRequestDto,
    GetBasketPaymentsApiResponse,
    GetBasketSummariesApiResponse,
    OrderTenderRequestDto,
    OrderTenderSummaryDto,
    PaymentDto,
    PaymentStatus,
    UpdateCheckPaymentRequest,
    WorkflowProgression,
    WorkstationSummaryDto,
} from '@src/api/CheckoutApi';
import { guid } from '@src/helpers/GenerationHelpers';
import {
    CashPaymentDetails,
    CertifiedFundsPaymentDetails,
    CheckPaymentDetails,
    DebitPaymentDetails,
} from '@src/store/PaymentSlice';

/**
 * Test data deps, updated on mutation.
 */
let mockBasketSummaries: BasketSummaryDto[];
let mockBasketsDb: Map<string, BasketDto>;

const initializeMockDbs = () => {
    mockBasketSummaries = generateMockBasketSummaries();
    mockBasketsDb = generateMockBasketsDb();
};

initializeMockDbs();


/**
 * Checkout API operations. These progress a pre-checkout cart through checkout
 */
const checkoutRoute = '/api/v1';
export const checkoutHandlers = [
    // fetch workstation drawer assignment info
    http.get(`${checkoutRoute}/workstations/:name/:store`, async ({ params }) => {
        // extract basket id from params
        // manually determine which mock basket details we pull. we control these so nbd.
        // return 404 if basket id does not apply to any of these
        const workstation: WorkstationSummaryDto = {
            isDrawerAssigned: true,
        };
        // find the basket
        await delay(1000);
        return HttpResponse.json(workstation);
    }),
    // get basket details by ID
    http.get(`${checkoutRoute}/baskets/:basketId`, async ({ params }) => {
        // extract basket id from params
        // manually determine which mock basket details we pull. we control these so nbd.
        // return 404 if basket id does not apply to any of these
        const { basketId } = params;

        // find the basket
        const basket = mockBasketsDb.get(basketId as string);
        await delay(1000);
        return HttpResponse.json(basket, { status: basket ? 200 : 404, statusText: basket ? '' : 'Basket not found' });
    }),
    // get basket details by ID
    http.get(`${checkoutRoute}/baskets/:basketId/workflow`, async ({ params }) => {
        // extract basket id from params
        // manually determine which mock basket details we pull. we control these so nbd.
        // return 404 if basket id does not apply to any of these
        const { basketId } = params;

        const steps: WorkflowProgression = {
            contractSessionId: '',
            steps: [
                { stepName: 'Payment', status: 'NOT_STARTED', isEnabled: true, remoteUrl: '' },
                { stepName: 'Signing Method', status: 'NOT_STARTED', isEnabled: true, remoteUrl: 'https://dochdlrqaeaststorage.blob.core.windows.net/docs-mfe-remotes/signingMethod' },
                { stepName: 'E-Sign', status: 'NOT_STARTED', isEnabled: true, remoteUrl: 'https://dochdlrqaeaststorage.blob.core.windows.net/docs-mfe-remotes/esign' },
                { stepName: 'Scan Docs', status: 'NOT_STARTED', isEnabled: true, remoteUrl: 'https://dochdlrqaeaststorage.blob.core.windows.net/docs-mfe-remotes/scan' },
                { stepName: 'Tender Order', status: 'NOT_STARTED', isEnabled: true, remoteUrl: '' },
            ],
        };

        // find the basket
        await delay(1000);
        return HttpResponse.json(steps);
    }),
    // get order tender summary by basket ID
    http.get(`${checkoutRoute}/baskets/:basketId/order-tender-summary`, async ({ params }) => {
        // extract basket id from params
        // manually determine which mock basket details we pull. we control these so nbd.
        // return 404 if basket id does not apply to any of these
        const { basketId } = params;

        // find the basket
        const basket = mockBasketsDb.get(basketId as string);
        if (!basket) return HttpResponse.json(null, { status: 404, statusText: 'Basket not found' });
        // crudely replicating the logic from the endpoint
        // not putting too many checks into place as we synthesize some data here
        const apItem = basket.lineItems.filter(i => i.lineItemType === 'APPRAISAL_ITEM')[0];
        const gapLi = apItem.children.filter(l => l.lineItemType === 'GAP_SERVICE_ITEM');
        const espLi = apItem.children.filter(l => l.lineItemType === 'ESP_SERVICE_ITEM');
        let processedPaymentsTotal = 0;
        let payoffToLienholder = 0;
        basket.payments.forEach(p => {
            if (p.direction === 'INBOUND') {
                if (p.status === 'PAID') processedPaymentsTotal += p.amount;
            } else {
                // TODO: confirm we need to filter by AUTHORIZED here and not a different one.
                // current logic in BFF
                if (p.status === 'AUTHORIZED' && p.paymentType === 'ACCOUNTS_PAYABLE') payoffToLienholder += p.amount;
            }
        });
        let orderTenderSummary: OrderTenderSummaryDto = {
            vehicleName: apItem.name,
            vehicleOfferValue: apItem.unitPrice,
            gapCancellationValue: gapLi.length > 0 ? gapLi[0].unitPrice : 0,
            espCancellationValue: espLi.length > 0 ? espLi[0].unitPrice : 0,
            payoffToLienholder,
            processedPaymentsTotal,
            equityTotal: apItem.unitPrice - payoffToLienholder,
            lienholderName: 'Fifth Third Bank',
        };
        await delay(1000);
        return HttpResponse.json(orderTenderSummary, {
            status: orderTenderSummary ? 200 : 404,
            statusText: orderTenderSummary ? '' : 'Basket not found',
        });
    }),
    // search for all baskets for the location.
    http.get(`${checkoutRoute}/baskets`, async ({ request }) => {
        const searchParams = new URL(request.url).searchParams;
        const term = searchParams.get('Term');
        const sellingLocationId = searchParams.get('SellingLocationId');
        const statuses = searchParams.get('Statuses');
        const paymentStates = searchParams.get('PaymentStates');
        const pageNumber = Number(searchParams.get('PageNumber'));
        const pageSize = Number(searchParams.get('PageSize'));
        const sortField = searchParams.get('SortField');
        const sortDirection = searchParams.get('SortDirection') === 'DESC' ? 'DESC' : 'ASC';
        const summaries = (term ?? '').includes('nope') ? [] : mockBasketSummaries;
        const filtered = summaries.filter(
            s =>
                (!term ||
                    s.customerName.toLowerCase().includes(term.toLowerCase()) ||
                    s.decoratorsSummary.some(
                        s => s.type === 'VIN' && s.values.some(v => v.toLowerCase().includes(term.toLowerCase()))
                    )) &&
                (!statuses || statuses.includes(s.status)) &&
                (!paymentStates || paymentStates.includes(s.paymentState)) &&
                (!sellingLocationId || s.sellingLocationId === sellingLocationId)
        );
        const results = orderBy(
            filtered,
            [sortField],
            [sortDirection === 'DESC' ? 'desc' : 'asc']
        ) as BasketSummaryDto[];
        const response: GetBasketSummariesApiResponse = {
            results,
            totalCount: results.length,
            pageNumber: pageNumber,
            pageSize: pageSize,
            totalPages: 20,
            sortField,
            sortDirection,
        };

        await delay(1000);
        return HttpResponse.json(response);
    }),
    // search for payments for a given basket.
    http.get(`${checkoutRoute}/baskets/:basketId/payments`, async ({ request, params }) => {
        // extract basket id from params
        // return 404 if basket id does not apply to any of these
        const { basketId } = params;
        const basket = mockBasketsDb.get(basketId as string);
        if (!basket) return HttpResponse.json(null, { status: 404, statusText: 'Basket not found' });

        // deconstruct search params
        const searchParams = new URL(request.url).searchParams;
        const paymentStatuses = searchParams.get('PaymentStatuses')?.split(',') as PaymentStatus[];
        const pageNumber = Number(searchParams.get('PageNumber'));
        const pageSize = Number(searchParams.get('PageSize'));
        const sortField = searchParams.get('SortField');
        const sortDirection = searchParams.get('SortDirection') === 'DESC' ? 'DESC' : 'ASC';
        // get the payments only for the basket id
        // only return payments that are in our target list of payment statuses
        // if no target statuses specified, return all payments for the basket
        const filtered =
            !paymentStatuses || paymentStatuses.length == 0
                ? basket.payments
                : basket.payments.filter(p => paymentStatuses.some(s => s == p.status));
        const results = orderBy(filtered, [sortField], [sortDirection === 'DESC' ? 'desc' : 'asc']) as PaymentDto[];
        const response: GetBasketPaymentsApiResponse = {
            results,
            totalCount: results.length,
            pageNumber: pageNumber,
            pageSize: pageSize,
            totalPages: 20,
            sortField,
            sortDirection,
        };

        await delay(1000);
        return HttpResponse.json(response);
    }),
    // Create a payment in OMS via the BFF
    http.post(`${checkoutRoute}/baskets/:basketId/payments`, async ({ request, params }) => {
        const { basketId } = params;
        // form JSON out of request body
        const createPaymentReq = (await request.json()) as CreateBasketPaymentRequestDto;
        // using the basket id from the request, manually determine if the targeted basket exists.
        // if it doesn't, then return 404.
        const targetBasket = mockBasketsDb.get(basketId as string);
        if (!targetBasket) {
            return HttpResponse.json(null, { status: 404, statusText: 'Basket not found' });
        }
        // otherwise, add the payment to the basket. return the payment DTO we'd create in the API,
        // doesn't have to be perfect since we aren't using the returned DTO anyways and will re-hydrate
        // the UI.
        let paymentToAdd: PaymentDto = {
            id: guid(),
            amount: createPaymentReq.amount,
            direction: createPaymentReq.direction,
            paymentType: createPaymentReq.paymentType,
            lineOfBusinessProvided: createPaymentReq.lineOfBusinessProvided,
            status: 'NEW',
            // partyrole?
        };
        switch (createPaymentReq.paymentType) {
            case 'CASH':
                break;
            // no additional information for adding a payment via cash.
            case 'CERTIFIED_FUNDS':
                paymentToAdd = {
                    ...paymentToAdd,
                    checkNumber: createPaymentReq.checkNumber,
                    bankName: createPaymentReq.bankName,
                } as CertifiedFundsPaymentDto;
            case 'CHECK':
                paymentToAdd = {
                    ...paymentToAdd,
                    checkNumber: createPaymentReq.checkNumber,
                } as CheckPaymentDto;
            case 'DEBIT':
                break;
            // no additional information for *adding* a payment with debit card.
            // we'll add the debit card suffix during processing.
        }
        const basket = mockBasketsDb.get(basketId as string);
        basket.payments.push(paymentToAdd);
        await delay(500);
        return HttpResponse.json(paymentToAdd);
    }),
    http.post(`${checkoutRoute}/baskets/:basketId/payments/:paymentId`, async ({ request, params }) => {
        const { basketId, paymentId } = params;
        const req = (await request.json()) as BasketPaymentActionRequestDto;
        const basket = mockBasketsDb.get(basketId as string);
        let update = basket.payments.find(p => p.id === paymentId) as PaymentDto;
        if (req.paymentAction === 'PROCESS') {
            update.status = 'PAID';
            switch (update.paymentType) {
                case 'CASH': {
                    update.cashCollected = (req.paymentDetails as CashPaymentDetails).cashCollected;
                    break;
                }
                case 'CHECK': {
                    update.checkNumber = (req.paymentDetails as CheckPaymentDetails).checkNumber;
                    break;
                }
                case 'CERTIFIED_FUNDS': {
                    update.checkNumber = (req.paymentDetails as CertifiedFundsPaymentDetails).checkNumber;
                    update.checkAmount = (req.paymentDetails as CertifiedFundsPaymentDetails).checkAmount
                    break;
                }
                case 'DEBIT': {
                    update.cardSuffix = (req.paymentDetails as DebitPaymentDetails).debitCardPresentPaymentDetails.accountNumberSuffix;
                    break;
                }
                default:
                    break;
            }
        } else if (req.paymentAction === 'VOID') {
            update.status = 'VOIDED';
        } else {
            update.status = 'REFUNDED';
        }
        basket.customerProcessedPaymentAmount += (req.paymentAction === 'PROCESS' ? 1 : -1) * update.amount;
        await delay(500);
        return HttpResponse.json(update);
    }),
    http.patch(`${checkoutRoute}/baskets/:basketId/payments/:paymentId`, async ({ request, params }) => {
        const { basketId, paymentId } = params;
        const req = (await request.json()) as UpdateCheckPaymentRequest;
        const basket = mockBasketsDb.get(basketId as string);
        let update = basket.payments.find(p => p.id === paymentId) as CheckPaymentDto;
        update.paymentType = req.paymentType;
        update.amount = req.amount;
        update.checkNumber = req.checkNumber;
        await delay(500);
        return HttpResponse.json(update);
    }),
    http.delete(`${checkoutRoute}/baskets/:basketId/payments/:paymentId`, async ({ params }) => {
        const { basketId, paymentId } = params;
        const basket = mockBasketsDb.get(basketId as string);
        const idx = basket.payments.findIndex(p => p.id === paymentId);
        basket.payments.splice(idx, 1);
        await delay(500);
        return new HttpResponse(null, { status: 204 });
    }),
    http.post(`${checkoutRoute}/orders`, async ({ request }) => {
        const req = (await request.json()) as OrderTenderRequestDto;
        const basket = mockBasketsDb.get(req.basketId);
        basket.basketStatus = 'COMPLETED';
        await delay(500);
        return HttpResponse.json(basket);
    }),
];