import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectShowControlPanel, setShowControlPanel } from '@src/store/MockHardwareSlice';
import { Offcanvas } from 'react-bootstrap';
import { MockHardwareControl } from './MockHardwareControl';
import '@horizon/icons/individual/hzn-gear';

export const MockHardwareCanvas = () => {
    const dispatch = useAppDispatch();
    const showHwPanel = useAppSelector(selectShowControlPanel);

    return (
        <>
            <div className="fab" onClick={() => dispatch(setShowControlPanel(!showHwPanel))}>
                <hzn-icon-gear class="fab-content" />
            </div>
            <Offcanvas
                show={showHwPanel}
                placement="start"
                backdropClassName="d-none"
                onHide={() => dispatch(setShowControlPanel(false))}
            >
                <Offcanvas.Header closeButton />
                <Offcanvas.Body>
                    <MockHardwareControl />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
