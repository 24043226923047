// set of Checkout Basket Details. They don't have their ID, so the mock Search endpoint will need to 

import { BasketDto, BasketLineItemDto, BasketSummaryDto, LineItemParticipantDto, PaymentDto } from "@src/api/CheckoutApi";
import { guid } from "@src/helpers/GenerationHelpers";

// universal constants for mock data
const mockLocationId = '7101';
const VIN1 = 'ABCD1234EFGH';
const VIN2 = 'EFGH1234IJKL';
const VIN3 = 'JKLM1234NOPQ';

// MOCK BASKET 1: 
// negative equity, no GAP/ESP, no inbound payments yet made
export const mockBasketCheckoutPayments1: PaymentDto[] = [
    {
        // only populating with relevant fields for UI read/update.
        paymentType: 'ACCOUNTS_PAYABLE',
        id: guid(),
        amount: 15000, // we paid lienholder $15k and acquired $10k car, customer owes $5k
        direction: 'OUTBOUND',
        status: 'AUTHORIZED',
    },
]

export const mockBasketAppraisalItemParticipants1: LineItemParticipantDto[] = [
    {
        id: guid(),
        name: 'FIFTH THIRD BANK',
        roles: [
            'LIEN_HOLDER'
        ]
    },
    {
        id: guid(),
        name: 'Christina Pays-A-Lot',
        roles: [
            'SELLER',
            'TITLE_HOLDER'
        ]
    }
]

export const mockBasket1LineItems: BasketLineItemDto[] = [
    {
        name: '2024 Mini Cooper S',
        itemId: '10009876',
        vin: 'JH4KA4670LC010014',
        lineItemType: 'APPRAISAL_ITEM',
        unitPrice: 10000,
        equity: -5000,
        quantity: 1, // should always be 1 for MVP
        children: [],
        participants: mockBasketAppraisalItemParticipants1
    }
]

export const basketId1 = 'f242372a-1597-4b57-aa30-69706fa3b5fb';

export const mockBasket1: BasketDto = {
    id: basketId1,
    basketOwnerName: 'Christina Pays-A-Lot',
    basketStatus: 'ACTIVE',
    customerProcessedPaymentAmount: 0,
    lineItems: mockBasket1LineItems,
    payments: mockBasketCheckoutPayments1
}


// MOCK BASKET 2: 
// positive equity, no GAP/ESP, no outbound payments yet made to customer
export const mockBasketCheckoutPayments2: PaymentDto[] = [
    {
        // only populating with relevant fields for UI read/update.
        paymentType: 'ACCOUNTS_PAYABLE',
        id: guid(),
        amount: 15000, // we paid lienholder $15k and acquired $20k car, we owe customer $5k
        direction: 'OUTBOUND',
        status: 'AUTHORIZED',
    },
    {
        // only populating with relevant fields for UI read/update.
        paymentType: 'BANK_DRAFT',
        id: guid(),
        amount: 5000, // we paid lienholder $15k and acquired $10k car, customer owes $5k
        direction: 'OUTBOUND',
        status: 'NEW',
    },
]

export const mockBasketAppraisalItemParticipants2: LineItemParticipantDto[] = [
    {
        id: guid(),
        name: 'FIFTH THIRD BANK',
        roles: [
            'LIEN_HOLDER'
        ]
    },
    {
        id: guid(),
        name: 'Christina Makes-A-Lot',
        roles: [
            'SELLER',
            'TITLE_HOLDER'
        ]
    }
]

export const mockBasket2LineItems: BasketLineItemDto[] = [
    {
        name: '2021 Chevy Trax LS',
        itemId: '10009876',
        vin: 'JH4KA4670LC010014',
        lineItemType: 'APPRAISAL_ITEM',
        unitPrice: 20000,
        equity: 5000,
        quantity: 1, // should always be 1 for MVP
        children: [],
        participants: mockBasketAppraisalItemParticipants2
    }
]

export const basketId2 = 'a296848d-1597-4b57-tt46-86847lk3b5fj';

export const mockBasket2: BasketDto = {
    id: basketId2,
    basketOwnerName: 'Christina Makes-A-Lot',
    basketStatus: 'ACTIVE',
    customerProcessedPaymentAmount: 0,
    lineItems: mockBasket2LineItems,
    payments: mockBasketCheckoutPayments2
}

// MOCK BASKET 3: 
// negative equity, no GAP/ESP present.
// Payments saved but not processed.
export const mockBasketCheckoutPayments3: PaymentDto[] = [
    {
        paymentType: 'ACCOUNTS_PAYABLE',
        id: guid(),
        amount: 25000, // we paid lienholder $25k and acquired $20k car, owe customer $5k initially
        direction: 'OUTBOUND',
        status: 'AUTHORIZED',
    },
    {
        paymentType: 'CASH',
        id: guid(),
        amount: 1000, // saved but not processed, doesn't count yet.
        direction: 'INBOUND',
        status: 'NEW'
    },
    {
        paymentType: 'DEBIT',
        id: guid(),
        amount: 1000, // saved but not processed, doesn't count yet.
        direction: 'INBOUND',
        status: 'NEW', // shouldn't have card suffix yet.
    },
    {
        paymentType: 'CHECK',
        id: guid(),
        amount: 250, // saved but not processed, doesn't count yet.
        direction: 'INBOUND',
        status: 'NEW',
        checkNumber: '1234'
    },
    {
        paymentType: 'CERTIFIED_FUNDS',
        id: guid(),
        amount: 2750, // saved but not processed, doesn't count yet.
        direction: 'INBOUND',
        status: 'NEW',
        checkNumber: '3456'
    },
]

export const mockBasketAppraisalItemParticipants3: LineItemParticipantDto[] = [
    {
        id: guid(),
        name: 'FIFTH THIRD BANK',
        roles: [
            'LIEN_HOLDER'
        ]
    },
    {
        id: guid(),
        name: 'Christina Makes-A-Lot',
        roles: [
            'SELLER',
            'TITLE_HOLDER'
        ]
    }
]

export const mockBasket3LineItems: BasketLineItemDto[] = [
    {
        name: '2021 Chevy Trax LS',
        itemId: '10009876',
        vin: 'JH4KA4670LC010014',
        lineItemType: 'APPRAISAL_ITEM',
        unitPrice: 20000,
        equity: -5000,
        quantity: 1, // should always be 1 for MVP
        children: [],
        participants: mockBasketAppraisalItemParticipants3
    }
]

export const basketId3 = 'b296848e-2597-4b57-tf46-86847lk3b5fj';

export const mockBasket3: BasketDto = {
    id: basketId3,
    basketOwnerName: 'Christina Saved-Payments',
    basketStatus: 'ACTIVE',
    customerProcessedPaymentAmount: 0,
    lineItems: mockBasket3LineItems,
    payments: mockBasketCheckoutPayments3
}

// TODO: what does GAP/ESP stuff look like?
// MOCK BASKET 4:
// negative equity, GAP/ESP present, no inbound payments made

// MOCK BASKET 5:
// negative equity, GAP/ESP present, some inbound payments made

// TODO: positive equity. what does this look like? need example

// export const mockBaskets = (): BasketDto[] => [JSON.parse(JSON.stringify(mockBasketDetails1))];

export const generateMockBasketsDb = (): Map<string, BasketDto> => {
    let ret = new Map<string, BasketDto>();
    ret.set(basketId1, mockBasket1);
    ret.set(basketId2, mockBasket2);
    ret.set(basketId3, mockBasket3);
    return ret;
}

export const generateMockBasketSummaries = (): BasketSummaryDto[] => {
    // generate basket summaries based off of current mock baskets, need to hard-code some values though.
    // these can be filtered and sorted by the MSW handler.
    // creating identifiers from a counter
    //const nowIso = formatISO(Date.now());
    const basketSummaryList: BasketSummaryDto[] = [];
    let summary1: BasketSummaryDto = {
        currencyCode: 'USD',
        sellingLocationId: mockLocationId,
        status: mockBasket1.basketStatus,
        statusDate: '2024-05-15T16:51:06.0967318Z',
        paymentState: 'NEW',
        equity: -5000,
        customerName: mockBasket1.basketOwnerName,
        customerPhoneNumber: '',
        id: basketId1,
        decoratorsSummary: [{ type: "VIN", values: [VIN1] }],
        createdOnUtc: '2024-05-15T16:51:06.0967318Z',
    }

    let summary2: BasketSummaryDto = {
        currencyCode: 'USD',
        sellingLocationId: mockLocationId,
        status: mockBasket2.basketStatus,
        statusDate: '2024-06-15T16:51:06.0967318Z',
        paymentState: 'NEW',
        inboundPaymentDue: 0,
        equity: 5000,
        customerName: mockBasket2.basketOwnerName,
        customerPhoneNumber: '',
        id: basketId2,
        decoratorsSummary: [{ type: "VIN", values: [VIN2] }],
        createdOnUtc: '2024-06-15T16:51:06.0967318Z',
    }

    let summary3: BasketSummaryDto = {
        currencyCode: 'USD',
        sellingLocationId: mockLocationId,
        status: mockBasket3.basketStatus,
        statusDate: '2024-06-15T16:51:06.0967318Z',
        paymentState: 'PARTIALLY_PAID',
        equity: -5000,
        customerName: mockBasket3.basketOwnerName,
        customerPhoneNumber: '',
        id: basketId3,
        decoratorsSummary: [{ type: "VIN", values: [VIN3] }],
        createdOnUtc: '2024-06-15T16:51:06.0967318Z',
    }

    basketSummaryList.push(summary1);
    basketSummaryList.push(summary2);
    basketSummaryList.push(summary3);
    return basketSummaryList;
}

// TODO: workstation endpoint usage not currently implemented. will need to update this part as needed.
// export const mockWorkstationSummary: WorkstationSummary = {
//     classicSystemsId: 12345,
//     isDrawerAssigned: true,
//     workstationType: 'STATIONARY',
//     storeLocationId: '7101',
//     name: '7101_STATIONARY_1',
//     isDeleted: false,
//     id: '55c6e9a5-fa0e-41c6-a662-f8244793f172'
// }

// TODO: remove after result is changed from paginated list, to singular result
// export const mockWorkstations = (): WorkstationSummaryPaginatedList => {
//     return {
//         pageNumber: 0,
//         pageSize: 1,
//         sortDirection: null,
//         sortField: null,
//         totalCount: 1,
//         results: [mockWorkstationSummary]
//     }
// }

