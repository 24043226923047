import { HttpResponse, delay, http } from 'msw';
import { setMockCashDrawerStatus } from '@src/store/MockHardwareSlice';
import { store } from '@src/store/store';

const getMockCashDrawer = () => store.getState().mockHardware.cashDrawer;
const getMockCardReader = () => store.getState().mockHardware.cardReader;
const getMockWorkstation = () => store.getState().mockHardware.workstation;

export const hardwareHandlers = [
    // Get the workstation name
    http.get(`/Workstation`, async () => {
        const workstation = getMockWorkstation();
        await delay(25);
        return HttpResponse.json(workstation.name);
    }),

    // Get the cash drawer status
    http.get(`/CashDrawer`, async () => {
        const cashDrawer = getMockCashDrawer();
        await delay(25);
        return HttpResponse.json(cashDrawer.status);
    }), 

    // Open the cash drawer
    http.post(`/CashDrawer`, async () => {
        const cashDrawer = getMockCashDrawer();
        await delay(25);
        switch (cashDrawer.status) {
            // if the cash drawer was closed, open it.
            case 'Closed': {
                store.dispatch(setMockCashDrawerStatus('Open'));
                return HttpResponse.json('Open');
            }
            // if the cash drawer was already open, this is a no-op.
            case 'Open':
            case 'AlreadyOpened': {
                return HttpResponse.json('AlreadyOpened');
            }
            case 'Offline': {
                return HttpResponse.json('Offline');
            }
        }
    }),

    // Get the card reader status
    http.get(`/CardReader`, async () => {
        const cardReader = getMockCardReader();
        await delay(25);
        return HttpResponse.json(cardReader.status);
    }),

    // Request a card read
    http.post(`/CardReader`, async ({ request }) => {
        const cardReader = getMockCardReader();
        await delay(3000);
        return HttpResponse.json(cardReader.cardReadResult);
    }),
];
