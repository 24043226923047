import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { selectCardReaderReady } from '@src/store/HardwareSlice';
import { Modal } from 'react-bootstrap';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { DebitPaymentDescription } from './DebitPaymentDescription';
import { CardData, CardReadResult, useRequestCardReadMutation } from '@src/api/hardware/EchoApi';

const tryAgain =
    'Try processing the payment again. If the issue is not resolved, try processing this payment again from a different computer, then partner with the Manager on Duty and enter a Service Station ticket.';

// TODO: Describe all error and recovery paths
const getCardErrorReason = (result: CardReadResult) => {
    switch (result.status) {
        case 'Error':
            return 'There was a card entry error.';
        case 'Cancelled':
            return 'The card entry was cancelled.';
        default:
            return 'Some other error occurred.';
    }
};

interface ReceiveCardDataProps {
    onReceived: (paymentDetails: CardData) => void;
    onCancel: () => void;
}

export const ReceiveCardData = (props: ReceiveCardDataProps) => {
    const cardReaderReady = useAppSelector(selectCardReaderReady);
    const [swipeCard, swipeCardResult] = useRequestCardReadMutation();
    const [cardErrorReason, setCardErrorReason] = useState<string>(undefined);
    const [retry, setRetry] = useState(false);

    const handleCardSwipeResult = (result: CardReadResult) => {
        switch (result.status) {
            case "Success":
            case "Manual": {
                props.onReceived(result.cardData);
                break;
            }
            default: {
                setCardErrorReason(getCardErrorReason(result));
                setRetry(true);
                break;
            }
        }
    };

    const receiveCardData = () => {
        console.log('receiveCardData()');
        setCardErrorReason(undefined);
        swipeCard({cardReadRequest: {amount: 1, customer: "foo", requireSignature: true}})
            .unwrap()
            .then(handleCardSwipeResult)
            .catch(err => setCardErrorReason(`The card reader malfunctioned.`));
    };

    useEffect(() => {
        if (cardReaderReady) {
            receiveCardData();
        }
    }, []);

    console.log('swipeCardResult', swipeCardResult);

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">Processing payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <DebitPaymentDescription />
                    <HorizonAlert
                        show={swipeCardResult.isLoading}
                        heading="Please follow the instructions on the card reader."
                        tone="info"
                    />
                    <HorizonAlert
                        show={!!cardErrorReason}
                        heading={cardErrorReason}
                        sub-heading={tryAgain}
                        tone="critical"
                    />
                    <HorizonAlert
                        show={cardReaderReady === false}
                        heading="The card reader is not responding."
                        sub-heading={tryAgain}
                        tone="caution"
                    />
                </FlexColumn>
            </Modal.Body>
            {retry && (
                <Modal.Footer>
                    <FlexColumn className="w-100">
                        <HorizonButton
                            variant="primary"
                            onClick={() => {
                                receiveCardData();
                                setRetry(false);
                            }}
                        >
                            Try Again
                        </HorizonButton>

                        <HorizonButton variant="tertiary" onClick={() => props.onCancel()}>
                            Cancel
                        </HorizonButton>
                    </FlexColumn>
                </Modal.Footer>
            )}
        </>
    );
};
