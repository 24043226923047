import { useGetBasketWorkflowProgressionQuery } from '@src/api/CheckoutApi';
import { Stepper } from '@src/components/Stepper/Stepper';
import { Suspense } from '@src/components/Suspense';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectActiveStep, selectSteps, setActiveStep, setSteps } from '@src/store/CheckoutSlice';
import { subscribeForWorkflowUpdateNotifications } from '@src/utils/SignalR';
import React, { useEffect } from 'react';

export interface CheckoutStepperProps {
    basketId: string;
}

const CheckoutStepper = ({ basketId }: CheckoutStepperProps) => {
    const dispatch = useAppDispatch();
    const steps = useAppSelector(selectSteps);
    const activeStep = useAppSelector(selectActiveStep);

    const workflowProgression = useGetBasketWorkflowProgressionQuery({
        basketId,
    });

    // reset all step state when component initializes
    useEffect(() => {
        dispatch(reset());
    }, []);

    // update step state when workflowProgression fetch completes
    useEffect(() => {
        if (workflowProgression.isSuccess) {
            dispatch(setSteps(workflowProgression.data));
        }
    }, [workflowProgression]);

    // set the active step and connect to SignalR service on first load of the workflowProgression
    useEffect(() => {
        if (!!steps && !activeStep) {
            dispatch(setActiveStep(steps[0]));
            subscribeForWorkflowUpdateNotifications(basketId, () => workflowProgression.refetch());
        }
    }, [steps, activeStep]);
  
    return (
        <Suspense suspended={!steps}>
            <Stepper label="Checkout Workflow" steps={steps} active={activeStep} />
        </Suspense>
    );
};

export default CheckoutStepper;
