import './horizon';
import './main.scss';
import { createRoot } from 'react-dom/client';
import { Host } from './Host';
import { ENVIRONMENT, PROXY_NAMES } from './config';
import { HttpHandler } from 'msw';
import { setupWorker } from 'msw/browser';
import { checkoutHandlers } from './.mocks/mswHandlers';
import { hardwareHandlers } from './.mocks/mswHardwareHandlers';

const mockHandlers: HttpHandler[] = [];

// Make mock Basket APIs available in local environments, unless disabled by tests.
if (ENVIRONMENT === 'mock') {
    // use localhost for API requests
    sessionStorage.setItem('com.unboundid.kmxpos.access_token', 'Bearer dev-token');
    sessionStorage.setItem('com.unboundid.kmxpos.proxy', PROXY_NAMES.mock);

    // Use mock claims
    sessionStorage.setItem(
        'com.unboundid.kmxpos.profile',
        JSON.stringify({
            name: 'Dev Loper',
            sub: '123456',
            email: 'Dev_L_Loper@bogus.com',
            'kmx.managerid': '234567',
            'kmx.jobcode': 'OFFMC',
            'kmx.jobtitle': 'Business Office Manager',
            'kmx.locationnumber': ['7101'],
            'kmx.managementlevel': 'Sr Manager',
            'kmx.jobfunction': 'Business Office',
            'kmx.roles': ['DEVMODE', 'POS-ADMIN'],
        })
    );

    // Use Basket mock handlers unless specifically disabled by tests
    if (!(window as any).E2EMSWEnabled) {
        mockHandlers.push(...checkoutHandlers);
    }
} else {
    sessionStorage.setItem('com.unboundid.kmxpos.proxy', PROXY_NAMES.pathfinders_oauthproxy);
}

// Make mock hardware APIs available in all non-prod environments.
if (ENVIRONMENT !== 'prod') {
    mockHandlers.push(...hardwareHandlers);
}

// If any mock API handlers are enabled, start MSW.
if (mockHandlers.length > 0) {
    setupWorker(...mockHandlers).start();
}

// Finally, render the app.
createRoot(document.getElementById('main-content')).render(<Host />);
