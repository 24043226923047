import { useGetCashDrawerStatusQuery, useOpenCashDrawerMutation } from '@src/api/hardware/EchoApi';
import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CashPaymentDescription } from './CashPaymentDescription';

interface TenderCashPaymentProps {
    OnContinue: () => void;
    onCancel: () => void;
}

/**
 * TenderCashPayment orchestrates and observes cash drawer activity when the BOA needs to enter cash and make change.
 * If the cash drawer is not functioning properly BOAs will be prompted to use a different terminal or open the drawer with a key. 
 */
export const TenderCashPayment = (props: TenderCashPaymentProps) => {
    const { mode } = useAppSelector(selectPayment);
    const [openCashDrawer, openCashDrawerResult] = useOpenCashDrawerMutation();
    const cashDrawerStatus = useGetCashDrawerStatusQuery(undefined, {
        pollingInterval: 500,
        skip: !openCashDrawerResult.isSuccess,
    });

    useEffect(() => {
        // drawer was opened and subsequently closed
        if (
            !cashDrawerStatus.isFetching &&
            cashDrawerStatus.isSuccess &&
            cashDrawerStatus.data === 'Closed'
        ) {
            props.OnContinue();
        } else {
            // no op
        }
    }, [cashDrawerStatus]);

    return (
        <>
            <Modal.Header>
                <hzn-heading size="xsmall">{mode === 'Process' ? 'Processing' : 'Void'} payment</hzn-heading>
            </Modal.Header>
            <Modal.Body>
                <FlexColumn>
                    <hzn-text>The cash drawer will open when you {mode === 'Process' ? 'tender' : 'void'} this payment.</hzn-text>
                    <CashPaymentDescription />
                    <HorizonAlert
                        show={openCashDrawerResult.isSuccess && ["Open", "AlreadyOpened"].includes(openCashDrawerResult.data)}
                        heading={`Close the cash drawer to finish ${mode === 'Process' ? 'tendering' : 'voiding'} this payment.`}
                        tone="info"
                    ></HorizonAlert>
                    <HorizonAlert
                        show={openCashDrawerResult.isError || (openCashDrawerResult.isSuccess && ["Offline"].includes(openCashDrawerResult.data))}
                        heading="Cash drawer not responding"
                        tone="caution"
                        sub-heading={`Try ${mode === 'Process' ? 'tendering' : 'voiding'} the payment again. If the issue is not resolved, 
                            ${mode === "Process" ? "try processing this payment again from a different computer or" : ""} 
                            partner with an MOD to manually open the drawer using the cash register key.`}
                        actionText="I opened the drawer with the cash register key."
                        onAction={() => props.OnContinue()}
                    ></HorizonAlert>
                </FlexColumn>
            </Modal.Body>
            {(!openCashDrawerResult.isSuccess || ["Offline"].includes(openCashDrawerResult.data)) && (
                <Modal.Footer>
                    <FlexColumn className="w-100">
                        <HorizonButton
                            variant="primary"
                            tone={mode == "Process" ? "interactive" : "critical"}
                            loading={openCashDrawerResult.isLoading}
                            onClick={() => openCashDrawer()}
                        >
                            {mode === "Process" ? "Tender" : "Void"} Payment
                        </HorizonButton>

                        <HorizonButton
                            variant="tertiary"
                            disabled={ openCashDrawerResult.isLoading }
                            onClick={() => props.onCancel()}
                        >
                            Cancel
                        </HorizonButton>
                    </FlexColumn>
                </Modal.Footer>
            )}
        </>
    );
};
