import { echoApiBase as echoApi } from './EchoApiBase';
export const addTagTypes = ['Home', 'Workstation', 'CashDrawer', 'CardReader'] as const;
const injectedRtkApi = echoApi
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            $get: build.query<$getApiResponse, $getApiArg>({
                query: () => ({ url: `/` }),
                providesTags: ['Home'],
            }),
            getWorkstationName: build.query<GetWorkstationNameApiResponse, GetWorkstationNameApiArg>({
                query: () => ({ url: `/Workstation` }),
                providesTags: ['Workstation'],
            }),
            getCashDrawerStatus: build.query<GetCashDrawerStatusApiResponse, GetCashDrawerStatusApiArg>({
                query: () => ({ url: `/CashDrawer` }),
                providesTags: ['CashDrawer'],
            }),
            openCashDrawer: build.mutation<OpenCashDrawerApiResponse, OpenCashDrawerApiArg>({
                query: () => ({ url: `/CashDrawer`, method: 'POST' }),
                invalidatesTags: ['CashDrawer'],
            }),
            getCardReaderStatus: build.query<GetCardReaderStatusApiResponse, GetCardReaderStatusApiArg>({
                query: () => ({ url: `/CardReader` }),
                providesTags: ['CardReader'],
            }),
            requestCardRead: build.mutation<RequestCardReadApiResponse, RequestCardReadApiArg>({
                query: queryArg => ({ url: `/CardReader`, method: 'POST', body: queryArg.cardReadRequest }),
                invalidatesTags: ['CardReader'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as EchoApi };
export type $getApiResponse = unknown;
export type $getApiArg = void;
export type GetWorkstationNameApiResponse = /** status 200 OK */ string;
export type GetWorkstationNameApiArg = void;
export type GetCashDrawerStatusApiResponse = /** status 200 OK */ CashDrawerStatus;
export type GetCashDrawerStatusApiArg = void;
export type OpenCashDrawerApiResponse = /** status 200 OK */ CashDrawerStatus;
export type OpenCashDrawerApiArg = void;
export type GetCardReaderStatusApiResponse = /** status 200 OK */ CardReaderConnectionStatus;
export type GetCardReaderStatusApiArg = void;
export type RequestCardReadApiResponse = /** status 200 OK */ CardReadResult;
export type RequestCardReadApiArg = {
    cardReadRequest: CardReadRequest;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
};
export type CashDrawerStatus = 'Offline' | 'Closed' | 'Open' | 'AlreadyOpened';
export type CardReaderConnectionStatus = 'Error' | 'Disconnected' | 'Connected';
export type CardDetailsCollectionMethod = 'Swiped' | 'ManuallyKeyed';
export type CardData = {
    accountNumberSuffix: string;
    expirationDate: string;
    cardDetailsCollectionMethod: CardDetailsCollectionMethod;
    encryptedPin: string;
    bin: string;
    encryptedData: string;
    keyData: string;
    pinKey: string;
    terminalId: string;
} | null;
export type CardReaderInputStatus = 'Cancelled' | 'Error' | 'Manual' | 'Retrying' | 'Success';
export type CardReadResult = {
    cardData?: CardData;
    status: CardReaderInputStatus;
};
export type CardReadRequest = {
    /** The customer's full name */
    customer: string;
    /** The transaction amount in USD, with no more than 2 decimals */
    amount: number;
    /** A flag indicating the transaction requires a signature */
    requireSignature: boolean;
};
export const {
    use$getQuery,
    useGetWorkstationNameQuery,
    useGetCashDrawerStatusQuery,
    useOpenCashDrawerMutation,
    useGetCardReaderStatusQuery,
    useRequestCardReadMutation,
} = injectedRtkApi;
