import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { CheckoutApi } from '@src/api/CheckoutApi';
import { EchoApi } from '@src/api/hardware/EchoApi';

import toastReducer from '../components/toast/ToastSlice';
import basketSearchReducer, { BasketSearchQuery } from '../pages/BasketSearch/BasketSearchSlice';
import authReducer from './AuthSlice';
import hardwareReducer from './HardwareSlice';
import paymentReducer from './PaymentSlice';
import checkoutReducer from './CheckoutSlice';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import mockHardwareReducer, { MockHardware } from './MockHardwareSlice';

export const store = configureStore({
    reducer: {
        toast: toastReducer,
        payment: paymentReducer,
        basketSearch: persistReducer<BasketSearchQuery>({ key: 'basketSearch', storage: sessionStorage }, basketSearchReducer),
        auth: authReducer,
        hardware: hardwareReducer,
        checkout: checkoutReducer,
        mockHardware: persistReducer<MockHardware>({ key: 'mockHardware', storage: localStorage }, mockHardwareReducer),
        // Add the generated reducer as a specific top-level slice
        [CheckoutApi.reducerPath]: CheckoutApi.reducer,
        [EchoApi.reducerPath]: EchoApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            CheckoutApi.middleware,
            EchoApi.middleware,
        ]),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
