import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CardReaderConnectionStatus,
    CardReaderInputStatus,
    CardReadResult,
    CashDrawerStatus,
} from '@src/api/hardware/EchoApi';
import { RootState } from './store';

type MockCardReader = {
    status: CardReaderConnectionStatus;
    cardReadResult: CardReadResult;
};

type MockCashDrawer = {
    status: CashDrawerStatus;
};

type MockWorkstation = {
    name: string;
};

export type MockHardware = {
    showControlPanel: boolean;
    useMockHardware: boolean;
    cardReader: MockCardReader;
    cashDrawer: MockCashDrawer;
    workstation: MockWorkstation;
};

export const initialState: MockHardware = {
    showControlPanel: false,
    useMockHardware: false,
    cashDrawer: {
        status: 'Closed',
    },
    cardReader: {
        status: 'Connected',
        cardReadResult: {
            status: 'Success',
            cardData: {
                accountNumberSuffix: 'AccountNumberSuffix',
                bin: 'Bin',
                cardDetailsCollectionMethod: 'Swiped',
                encryptedData: 'EncryptedData',
                encryptedPin: 'EncryptedPin',
                expirationDate: 'ExpirationDate',
                keyData: 'KeyData',
                pinKey: 'PinKey',
                terminalId: 'TerminalId',
            },
        },
    },
    workstation: {
        name: 'MOCK-WORKSTATION',
    },
};

export const MockHardwareSlice = createSlice({
    name: 'mockHardware',
    initialState,
    reducers: {
        setShowControlPanel: (state, action: PayloadAction<boolean>) => {
            state.showControlPanel = action.payload;
        },
        setUseMockHardware: (state, action: PayloadAction<boolean>) => {
            state.useMockHardware = action.payload;
        },
        setMockWorkstationName: (state, action: PayloadAction<string>) => {
            state.workstation.name = action.payload;
        },
        setMockCashDrawerStatus: (state, action: PayloadAction<CashDrawerStatus>) => {
            state.cashDrawer.status = action.payload;
        },
        setMockCardReaderStatus: (state, action: PayloadAction<CardReaderConnectionStatus>) => {
            state.cardReader.status = action.payload;
        },
        setMockCardReaderSwipeStatus: (state, action: PayloadAction<CardReaderInputStatus>) => {
            state.cardReader.cardReadResult.status = action.payload;
        },
    },
});

export const {
    setShowControlPanel,
    setUseMockHardware,
    setMockWorkstationName,
    setMockCashDrawerStatus,
    setMockCardReaderStatus,
    setMockCardReaderSwipeStatus,
} = MockHardwareSlice.actions;

export const selectShowControlPanel = (state: RootState) => state.mockHardware.showControlPanel;
export const selectUseMockHardware = (state: RootState) => state.mockHardware.useMockHardware;
export const selectMockCashDrawer = (state: RootState) => state.mockHardware.cashDrawer;
export const selectMockCardReader = (state: RootState) => state.mockHardware.cardReader;
export const selectMockWorkstation = (state: RootState) => state.mockHardware.workstation;

export default MockHardwareSlice.reducer;
