import { ENVIRONMENT } from '@src/config';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectStoreId, setStoreId } from '@src/store/AuthSlice';
import { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { MockHardwareCanvas } from './Hardware/MockHardwareCanvas';

type StoreOutletProps = {
    storeId?: string;
};

export const StoreOutlet = ({ storeId: storeIdFromProps }: StoreOutletProps) => {
    const dispatch = useAppDispatch();
    const { storeLocationId: storeIdFromParams } = useParams();

    useEffect(() => {
        dispatch(setStoreId(storeIdFromProps ?? storeIdFromParams));
    }, [storeIdFromProps, storeIdFromParams]);

    const storeId = useAppSelector(selectStoreId);

    // If the store location doesn't have a valid integer format, show an alert.
    // Otherwise, show nested app content.
    return (
        <>
            {!storeId && (
                <hzn-alert
                    show={true}
                    tone="critical"
                    heading="Unrecognized Store ID."
                    sub-heading="Your browser location does not appear to contain a valid Store ID. Please press the Back button and retry your operation. If the problem persists please contact the Solution Center."
                />
            )}
            {storeId && (
                <>
                    <Outlet />
                    {ENVIRONMENT !== 'prod' && <MockHardwareCanvas />}
                </>
            )}
        </>
    );
};
