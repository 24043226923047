import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Claims } from '../models/Claims';
import { RootState } from './store';

export interface AuthState {
    storeId: string | undefined;
    accessToken: string | undefined;
    claims: Claims | undefined;
}

const initialState: AuthState = {
    storeId: undefined,
    accessToken: undefined,
    claims: undefined,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setStoreId: (state, action: PayloadAction<string>) => {
            const inputStoreId = (action.payload ?? "").trim(); 
            const resolvedStoreId = isNaN(parseInt(inputStoreId)) ? undefined : inputStoreId;
            if (resolvedStoreId)
                console.log(`Store ID updated to '${resolvedStoreId}'`)
            else
                console.warn(`'${action.payload}' is not a valid store ID.`);
            state.storeId = resolvedStoreId;
        },
        setAuthState: (state, action: PayloadAction<AuthState>) => {
            state.claims = action.payload.claims;
            state.accessToken = action.payload.accessToken;
        },
    },
});

export const { setStoreId, setAuthState } = AuthSlice.actions;

export const selectStoreId = (state: RootState) => state.auth.storeId;
export const selectClaims = (state: RootState) => state.auth.claims;
export const selectIsLoggedIn = (state: RootState) => state.auth.claims !== undefined;

export default AuthSlice.reducer;


